
<template>
    <div class="chat__contact flex items-center px-2 pt-2 pb-1" :class="{'bg-primary-gradient text-white shadow-lg': isActiveChatUser}">
        <div class="contact__avatar mr-1">
            <vs-avatar class="border-2 border-solid border-white" :src="absoluteUrl(contact.image)" size="42px" />
        </div>
        <div class="contact__container w-full flex items-center justify-between overflow-hidden">
            <div class="contact__info flex flex-col truncate w-5/6">
                <h6 class="font-semibold" :class="{'text-white': isActiveChatUser}">{{ contact.first_name}} {{ contact.last_name}}</h6>
                <span class="truncate pt-1 text-sm">{{ $store.getters['chat/chatLastMessaged'](contact.id) ? $store.getters['chat/chatLastMessaged'](contact.id).message : contact.about }}</span>
            </div>
            <div class="chat__contact__meta flex self-start flex-col items-end w-1/6">
                <span class="whitespace-no-wrap">{{ lastMessaged | date_parse }}</span>
                <vs-chip class="number" color="primary" v-if="unseenMsg">{{ unseenMsg }}</vs-chip>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  props: {
    contact          : { type: Object,  required: true, },
    isActiveChatUser : { type: Boolean },
    lastMessaged     : { type: String,  default : "", },
    unseenMsg        : { type: Number,  default : 0, }
  },
  data() {
    return {

    }
  }
}
</script>

