
<template>
    <div id="component-chat-log" class="m-8" v-if="chatData">
        <div v-for="(msg, index) in chatData" class="msg-grp-container" :key="index">

            <!-- If previous msg is older than current time -->
            <template v-if="chatData[index-1]">
                <vs-divider v-if="!isSameDay(msg.created_at, chatData[index-1].created_at)" class="msg-time">
                    <span>{{ toDate(msg.created_at) }}</span>
                </vs-divider>
                <div class="spacer mt-8" v-if="!hasSentPreviousMsg(chatData[index-1].from, msg.from)"></div>
            </template>

            <div class="flex items-start" :class="[{'flex-row-reverse' : isActiveSender(msg.from) }]">
                <template v-if="chatData[index-1]">
                    <template v-if="(!hasSentPreviousMsg(chatData[index-1].from, msg.from) || !isSameDay(msg.created_at, chatData[index-1].created_at))">
                        <vs-avatar size="40px" class="border-2 shadow border-solid border-white m-0 flex-shrink-0" :class="isActiveSender(msg.from) ? 'sm:ml-5 ml-3' : 'sm:mr-5 mr-3'" :src="senderImg(msg.from)"></vs-avatar>
                    </template>
                </template>

                <template v-if="index == 0">
                    <vs-avatar size="40px" class="border-2 shadow border-solid border-white m-0 flex-shrink-0" :class="isActiveSender(msg.from) ? 'sm:ml-5 ml-3' : 'sm:mr-5 mr-3'" :src="senderImg(msg.from)"></vs-avatar>
                </template>

                <template v-if="chatData[index-1]">
                    <div class="mr-16" v-if="!(!hasSentPreviousMsg(chatData[index-1].from, msg.from) || !isSameDay(msg.created_at, chatData[index-1].created_at))"></div>
                </template>

                <div class="msg break-words relative shadow-md rounded py-2 px-4 mb-2 rounded-lg max-w-sm" :class="{'bg-primary-gradient text-white': isActiveSender(msg.from), 'border border-solid border-grey-light bg-white': !isActiveSender(msg.from)}">
                    <span>{{ msg.message }}</span>

                    <file-display :list="msg.chat_files" />

                    <div :class="{'text-right': isActiveSender(msg.from)}">
                        <span style="font-size: 10px;">{{ msg.created_at | date_ago }}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import FileDisplay from '@/components/FileDisplay.vue'

export default{
    components:{
        FileDisplay
    },
    props: {
        userId: {
            type: Number,
            required: true,
        }
    },
    data() {
        return {
            activeUser: this.$store.state.AppActiveUser,
        }
    },
    computed: {
        isActiveSender(){
            return (fromId) => this.activeUser.id == fromId
        },
        chatData() {
            return JSON.parse(JSON.stringify(this.$store.getters['chat/chatDataOfUser'](this.userId)));
        },
        senderImg() {
            return (isSentByActiveUser) => {
              if (isSentByActiveUser == this.activeUser.id) {
                return this.absoluteUrl(this.activeUser.image)
              }else {
                return this.absoluteUrl(this.$store.getters['chat/contact'](this.userId).image)
              }
            }
        },
        hasSentPreviousMsg() {
            return (last_sender, current_sender) => last_sender == current_sender
        },
    },
    methods: {
        isSameDay(time_to, time_from) {
            const date_time_to = new Date(Date.parse(time_to))
            const date_time_from = new Date(Date.parse(time_from));
            return date_time_to.getFullYear() === date_time_from.getFullYear() &&
                date_time_to.getMonth() === date_time_from.getMonth() &&
                date_time_to.getDate() === date_time_from.getDate();
        },
        toDate(time) {
            const locale = "en-us";
            const date_obj = new Date(Date.parse(time));
            const monthName= date_obj.toLocaleString(locale, {
                month: 'short'
            });
            return date_obj.getDate() + ' '  + monthName;
        },
        scrollToBottom() {
            this.$nextTick(() => {
                this.$parent.$el.scrollTop = this.$parent.$el.scrollHeight
            });
        }
    },
    updated() {
        this.scrollToBottom();
    },
    mounted() {
        this.scrollToBottom();
    }
}
</script>
